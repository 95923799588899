"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setButtonIcon = exports.getWidgetSettings = exports.hasClass = exports.setAlignment = void 0;
var common_1 = require("../common");
var setAlignment = function (container, alignment) {
    if (alignment && alignment === 'left') {
        container.classList.add('has-left-alignment');
    }
};
exports.setAlignment = setAlignment;
var hasClass = function (element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
};
exports.hasClass = hasClass;
var getWidgetSettings = function (paramsServer, clientId) { return __awaiter(void 0, void 0, Promise, function () {
    var protocol, url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                protocol = paramsServer.includes('localhost:') ? 'http://' : 'https://';
                url = "".concat(protocol).concat(paramsServer, "/widget/settings");
                return [4 /*yield*/, fetch(url, { headers: { 'client-id': clientId.toString() } }).catch(function (error) {
                        return console.error('Error', error);
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.json()];
        }
    });
}); };
exports.getWidgetSettings = getWidgetSettings;
var getClosedChatIcon = function (color) { return "\n  <svg width=\"56\" height=\"56\" viewBox=\"0 0 56 56\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <circle cx=\"28\" cy=\"28\" r=\"28\" fill=\"".concat(color, "\"/>\n    <path d=\"M28 15C35.1796 15 41 20.8205 41 28.0002C41 31.5904 39.545 34.8403 37.1925 37.1921L40.1466 40.1465C40.4615 40.4615 40.2384 41 39.793 41H28C20.8204 41 15 35.18 15 27.9998C15 20.82 20.8199 15 28 15Z\" fill=\"white\"/>\n    <circle cx=\"22.75\" cy=\"28\" r=\"1.75\" fill=\"").concat(color, "\"/>\n    <circle cx=\"28\" cy=\"28\" r=\"1.75\" fill=\"").concat(color, "\"/>\n    <circle cx=\"33.25\" cy=\"28\" r=\"1.75\" fill=\"").concat(color, "\"/>\n  </svg>\n"); };
var getOpenedChatIcon = function (color) { return "\n  <svg width=\"56\" height=\"56\" viewBox=\"0 0 56 56\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <circle cx=\"28\" cy=\"28\" r=\"28\" fill=\"".concat(color, "\"/>\n    <path d=\"M35.5 26L28 33.5L20.5 26\" stroke=\"white\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n  </svg>\n"); };
var setButtonIcon = function (button, color) {
    if (color === void 0) { color = common_1.DEFAULT_BACKGROUND_COLOR; }
    return function (isOpen) {
        if (!button)
            return;
        button.innerHTML = isOpen ? getOpenedChatIcon(color) : getClosedChatIcon(color);
    };
};
exports.setButtonIcon = setButtonIcon;
