"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RECAPTCHA_URL = exports.RECAPTCHA_KEY_ID = exports.MAX_FILES_SIZE_BYTES = exports.MAX_FILES_SIZE_MEGABYTES = exports.DEFAULT_TEXT_COLOR = exports.DEFAULT_BACKGROUND_COLOR = exports.WIDGET_STATE = void 0;
exports.WIDGET_STATE = {
    WRITE_TO_US_FORM: 'form',
    CHAT: 'chat',
    KNOWLEDGE_BASE: 'knowledge',
};
exports.DEFAULT_BACKGROUND_COLOR = '#12a489';
exports.DEFAULT_TEXT_COLOR = '#262732';
exports.MAX_FILES_SIZE_MEGABYTES = 15;
exports.MAX_FILES_SIZE_BYTES = exports.MAX_FILES_SIZE_MEGABYTES * 1024 * 1024;
exports.RECAPTCHA_KEY_ID = '6LeibikeAAAAAO8H8EMAVlAK5dwnyETr9sJ93btA';
exports.RECAPTCHA_URL = "https://www.google.com/recaptcha/api.js?render=".concat(exports.RECAPTCHA_KEY_ID);
