"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isImageFile = exports.truncateMiddle = exports.formatDate = exports.isValidEmail = void 0;
var isValidEmail = function (email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
exports.isValidEmail = isValidEmail;
var formatDate = function (dateString) {
    if (!dateString)
        return '';
    // need for Safari: Replace space with 'T' to conform to ECMA-262 standard ()
    var standardizedDateString = dateString.replace(' ', 'T') + 'Z';
    var inputDate = new Date(standardizedDateString);
    var userLocale = navigator.language || 'en-US';
    var now = new Date();
    var todayUTC = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
    var inputDayUTC = new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
    if (inputDayUTC >= todayUTC) {
        return inputDate.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit' });
    }
    else {
        return inputDate.toLocaleString(userLocale, { hour: '2-digit', minute: '2-digit', day: 'numeric', month: 'short' });
    }
};
exports.formatDate = formatDate;
var truncateMiddle = function (str, max) {
    if (max === void 0) { max = 15; }
    if (str.length <= max)
        return str;
    var splitAt = Math.floor(max / 2);
    var start = str.slice(0, splitAt);
    var end = str.slice(-splitAt);
    return "".concat(start, "...").concat(end);
};
exports.truncateMiddle = truncateMiddle;
var isImageFile = function (fileName) {
    return fileName.endsWith('.png') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg');
};
exports.isImageFile = isImageFile;
