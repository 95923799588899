"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeRecaptcha = exports.loadRecaptchaScript = void 0;
var __1 = require("..");
var loadRecaptchaScript = function (callback) {
    var existingScript = document.getElementById('recaptcha-enterprise-script');
    if (!existingScript) {
        var script = document.createElement('script');
        script.src = __1.RECAPTCHA_URL;
        script.id = 'recaptcha-enterprise-script';
        script.async = true;
        script.defer = true;
        script.onload = function () {
            if (callback)
                callback();
        };
        document.body.appendChild(script);
    }
    else if (callback)
        callback();
};
exports.loadRecaptchaScript = loadRecaptchaScript;
var removeRecaptcha = function () {
    var _a;
    var recaptchaBadge = document.querySelector('.grecaptcha-badge');
    var recaptchaScript = document.getElementById('recaptcha-enterprise-script');
    recaptchaScript === null || recaptchaScript === void 0 ? void 0 : recaptchaScript.remove();
    (_a = recaptchaBadge === null || recaptchaBadge === void 0 ? void 0 : recaptchaBadge.parentElement) === null || _a === void 0 ? void 0 : _a.remove();
};
exports.removeRecaptcha = removeRecaptcha;
